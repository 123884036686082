.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 600px) {
  .css-1q3x6u1-MuiContainer-root {
    max-width: 400px !important;
  }
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 16px !important;
  background: white !important;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.css-iz3z40-MuiDialog-container {
  transition: opacity 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.css-uhb5lp {
  border-radius: 16px !important;
  min-width: 400px;
}

.css-1y6pnir {
  border-radius: 16px !important;
  box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px !important;
  min-width: 550px;
}

.css-919eu4 {
  transition: opacity 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.css-1r09u4m {
  border: 0 !important;
}

.css-4o7e00 {
  transition: box-shadow 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 18px 0 0 18px;
  box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px !important;
}

.css-sl0zss {
  background-color: #ffffff00 !important;
}

.css-14x1nlk {
  color: rgb(0 0 0 / 90%) !important;
}

.css-ekeie0 {
  transition: opacity 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.css-n4md0y-MuiPaper-root-MuiDialog-paper {
  transition: box-shadow 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  transition: opacity 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.card-element {
  padding: 15px 12px;
  color: #272727;
  overflow: hidden;
  font-family: Nunito;
  letter-spacing: normal;
  height: 50px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
    display: none;
}

.gm-fullscreen-control {
    border-radius: 10px !important;
}

.css-to4x1n-MuiBadge-badge {
    border-radius: 5px;
}

.css-gmqabg-MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom: 0px !important;
}

.css-gmqabg-MuiInputBase-root-MuiFilledInput-root:after {
    border-bottom: 0px !important;
}

.css-14okwfx-MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom: 0px !important;
}

.css-14okwfx-MuiInputBase-root-MuiFilledInput-root:after {
    border-bottom: 0px !important;
}

.css-1ypv66n-MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom: 0px !important;
}

.css-1ypv66n-MuiInputBase-root-MuiFilledInput-root:after {
    border-bottom: 0px !important;
}

.css-19pebyf-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:before {
    border-bottom: 0px !important;
}

.css-19pebyf-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:after {
    border-bottom: 0px !important;
}

.css-15r5u9y-MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom: 0px !important;
}

.css-15r5u9y-MuiInputBase-root-MuiFilledInput-root:after {
    border-bottom: 0px !important;
}

.css-1d9k0sv-MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom: 0px !important;
}

.css-1d9k0sv-MuiInputBase-root-MuiFilledInput-root:after {
    border-bottom: 0px !important;
}

.css-1rg1m6g-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:before {
    border-bottom: 0px !important;
}

.css-1rg1m6g-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:after {
    border-bottom: 0px !important;
}

.css-279jec-MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom: 0px !important;
}

.css-279jec-MuiInputBase-root-MuiFilledInput-root:after {
    border-bottom: 0px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.css-1hju3x6-MuiPaper-root-MuiDialog-paper {
  border-radius: 12px !important;
  box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px !important;
  min-width: 540px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 12px !important;
  min-width: 340px;
  box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px !important;
}

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #000000 !important;
}

.css-1a590zg-MuiButtonBase-root-MuiButton-root:hover {
  background-color: none !important;
}

.css-tlc64q-MuiPaper-root-MuiDialog-paper {
  border-radius: 12px !important;
}
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: #00000036 !important;
}

.css-sox5kk-MuiBackdrop-root {
  background-color: #00000036 !important;
}

.MuiDialogtest-container {
  float: right;
}


.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;fill-opacity:0;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FAB11B;}
	.st2{fill:#D9961C;}
	.st3{fill:#FFFFFF;}
	.st4{fill-rule:evenodd;clip-rule:evenodd;fill:#149035;}
	.st5{fill:#108834;}
	.st6{fill-rule:evenodd;clip-rule:evenodd;fill:#7E0F82;}
	.st7{fill:#66126B;}

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,700;0,800;1,200&display=swap');

.css-140puxv-MuiTypography-root-MuiDialogTitle-root {
  font-family: Nunito !important;
  letter-spacing: normal !important;
  font-weight: 800 !important;
}

p {
  font-family: Nunito !important;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  font-family: Nunito !important;
}

.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root {
  font-family: Nunito !important;
  font-weight: 600 !important;
}

.hoversidemneu:hover {
  font-weight: 800 !important;
}

.css-sl0zss::before {
    border-bottom: 2px solid lightslategray !important;
}

.css-r8u8y9 {
    padding: 0 !important;
}

.css-177ic5c {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
  border-radius: 14px !important;
}

.css-1uqnmco::before {
    border-bottom: 2px solid lightslategray !important;
}
.css-17spqnz::before {
    border-bottom: 2px solid lightslategray !important;
}
.css-1oml8th::before {
    border-bottom: 2px solid lightslategray !important;
}

.css-c39gw3::before {
    border-bottom: 2px solid lightslategray !important;
}

.css-1rgmeur.Mui-focused {
    color: #000000 !important;
    font-family: Nunito !important;
    font-weight: 600;
}

.css-c39gw3 {
  background-color: #ffffff00;
}

.css-oxi3kn {
  background-color: #ffffff00;
}

.css-oxi3kn {
  transition: opacity 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.css-1uqnmco {
  transition: opacity 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.css-17spqnz {
  background-color: #ffffff00 !important;
}

.css-sl0zss::after {
    border-bottom: 2px solid lightslategray !important;
}

.css-g9m4e0-MuiInputBase-root-MuiFilledInput-root {
    background-color: white !important;
}

.css-19mk8g1-MuiInputBase-root-MuiFilledInput-root {
    background-color: white !important;
}

.css-g9m4e0-MuiInputBase-root-MuiFilledInput-root:before {
  border-bottom: 2px solid lightslategray !important;
}

.css-19mk8g1-MuiInputBase-root-MuiFilledInput-root:before {
  border-bottom: 2px solid lightslategray !important;
}

.css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:before {
  border-bottom: 2px solid lightslategray !important;
}

input:-internal-autofill-selected {
    background-color: white !important;
}

.css-gjbq6i-MuiInputBase-root-MuiFilledInput-root {
    border-bottom: 2px solid lightslategray !important;
    background-color: white !important;
}

.css-g9m4e0-MuiInputBase-root-MuiFilledInput-root:after {
    border-bottom: 2px solid lightslategray !important;
    background-color: white !important;
}

.css-gjbq6i-MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom: 0px solid lightslategray !important;
}

.css-gjbq6i-MuiInputBase-root-MuiFilledInput-root:after {
    border-bottom: 0px solid rgb(0 0 0 / 32%) !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0 !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    border-radius: 12px !important;
    box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px !important;
}

div {
  -webkit-tap-highlight-color: transparent;
}

fieldset {
  -webkit-tap-highlight-color: transparent;
  border-width: 0px !important;
}

.css-fvipm8 {
  border-radius: 12px !important;
  background: white !important;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.css-1t4vnk2-MuiDialogContent-root {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

div.inputstyletonormal input {
  color: #2c3136 !important;
  padding: 0  !important;
}

@media (max-width: 599px) {
  div.ocultatipodeordenrencel p {
    display: none;
  }
  div.ocultatipodeordenrencel svg {
    height: 26px !important;
    font-size: 26px !important;
    color: #040607 !important;
  }
  div.ocultatipodeordenrencel .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    display: none;
  }
  div.ocultatipodeordenrencel .css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
    display: none;
  }
  div.ocultatipodeordenrencel .css-1636szt {
    display: none !important;
  }
  div.ocultatipodeordenrencel .css-1yhq19e {
    display: none !important;
  }
}

@media (min-width: 600px) {
  .showonphone {
    display: none !important;
  }
  .showonwebandtablet {
    display: block !important;
  }
  .css-n4md0y-MuiPaper-root-MuiDialog-paper {
    border-radius: 18px 0 0 18px;
    box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px !important;
  }
}

@media (min-width: 600px) and (max-width: 999px) {
  .showontablet {
    display: block !important;
  }
  div.ocultatipodeordenrentablet div {
    margin-top: 0px;
  }
  div.ocultatipodeordenrentablet .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    margin-top: 0px;
  }
  div.ocultatipodeordenrentablet .css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
    margin-top: 0px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .css-1hyl1h2 {
    max-width: 85%!important;
    width: 85% !important;
    height: 90%!important;
    border-radius: 16px!important;
  }
  .css-m9glnp-MuiPaper-root-MuiDialog-paper {
    max-width: 85%!important;
    width: 85% !important;
    height: 90%!important;
    border-radius: 16px!important;
  }
}

@media (min-width: 900px) {
  .css-13wv78x-MuiInputBase-root .MuiInputBase-input {
      width: 35ch;
  }
  .css-1w7pj27 .MuiInputBase-input {
      width: 35ch;
  }
}
