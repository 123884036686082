.container {
    width: 100vw;
    height: 100vh;
    display: flex; /* Default Axis is X*/
    justify-content: center; /* Main Axis */
    align-items: center; /* Cross Axis */
  }
  /* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  .container {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}
  .indicator{
    width: 15vw;
  }